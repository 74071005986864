import React from "react"
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import Banner from "../components/Index/Banner"
import FeaturedServices from "../components/DigitalMarketingAgency/FeaturedServices"
import Partner from "../components/DigitalMarketingAgency/Partner"
import FunFacts from "../components/DigitalMarketingAgency/FunFacts"
import Testimonials from "../components/DigitalMarketingAgency/Testimonials"
import StartProject from "../components/DigitalMarketingAgency/StartProject"
import TeamMembers from "../components/DataAnalyticsAIStartup/TeamMembers"

const Index = () => {
    return (
        <Layout>
            <NavbarStaging />
            <Banner />
            <TeamMembers />
            <FunFacts />
            <FeaturedServices />
            <Testimonials />
            <StartProject />
            <Partner />
            <Footer />
        </Layout>
    );
}

export default Index