import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import Scientist9 from '../../assets/images/home-staging-tips.webp'
import Scientist10 from '../../assets/images/living-room-home-staging.jpg'
import staging from '../../assets/images/team/staging.jpg'
import shape1 from '../../assets/images/shape/map-shape1.png'
import shape4 from '../../assets/images/shape/vector-shape4.png'
import shape5 from '../../assets/images/shape/vector-shape5.png'

const TeamMembers = () => {
    return (
        <div className="scientist-area pt-100 pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12">
                        <div className="scientist-box-list">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-md-6 offset-lg-1">
                                    <div className="single-scientist-item">
                                        <img src={Scientist9} alt="Scientist" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-scientist-item">
                                        <img src={Scientist10} alt="Scientist" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-12 col-md-6 offset-lg-0 offset-md-3">
                                    <div className="single-scientist-item">
                                        <img src={staging} alt="banner" />
                                    </div>
                                </div>
                            </div>

                            <div className="map-shape1">
                                <img src={shape1} alt="banner" />
                            </div>
                            <div className="vector-shape5">
                                <img src={shape5} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-12">
                        <div className="scientist-section-title">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Our Team
                            </span>
                            <h2>JJ Home Staging</h2>
                            <p>Committed to helping customer improve their quality of life, and maximizing the value of each property, we aim to create the most effective transactions at the highest value. JJ Staging, make your house a home!</p>

                            <Link to="/contact" className="default-btn">
                                <i className="flaticon-view"></i>
                                View Our Team <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape4">
                <img src={shape4} alt="banner" />
            </div>
        </div>
    )
}

export default TeamMembers